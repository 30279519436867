import { defineStore  } from 'pinia'
import * as individualService from '@/services/service.individual';
import _ from 'lodash';

const objState = { 
  individual: null
};

export const useStoreIndividual = defineStore('individual', {
  state: () => ( _.cloneDeep(objState) ),
  getters: {
    
  },
  actions: {
    async getIndividualById (id) {
      return individualService.getIndividualById(id)
        .then((res) => {
          this.individual = _.cloneDeep(res.data);
          return res.data;
        })
        .catch((err) => {
          throw err;
        });
    },    

  },
});


